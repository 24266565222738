import React from "react"
import Header from "./Header"
import Breadcrumbs from "./Breadcrumbs"
import Footer from "./Footer"

export default function Layout({ children, breadcrumbs, container = true }) {
  return (
    <div>
      <Header />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={`main ${container ? 'container': ''}`}>{children}</div>
      <Footer />
    </div>
  )
}
