import React from "react"
import { Link } from "gatsby"
import Home from "../../../static/images/icons/home.svg"

export default function Breadcrumbs({
  breadcrumbs = [{ title: "Home", url: "/" }],
}) {

  return (
    <div className="breadcrumb gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner">
              <div className="breadcrumb-text">
                <p className="breadcrumb-elements">
                  <Link to="/">
                    <Home className="home-icon" />
                  </Link>
                  {breadcrumbs.map((edge, i) => {
                    return (
                      <span key={i} className="breadcrumb-element">
                        <i />
                        <Link
                          className="breadcrumb_last"
                          aria-current="page"
                          to={edge.url}
                        >
                          {edge.title}
                        </Link>
                      </span>
                    )
                  })}
                </p>
                <h1>{breadcrumbs[breadcrumbs.length - 1].title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
