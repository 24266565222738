import React from "react"
import SEO from "../components/global/Seo"
import Layout from "../components/layout/Layout"

import { graphql } from "gatsby"
import Img from "gatsby-image"

export default function UbezpieczenieRodzinne({ data }) {
  const { prices } = data
  const { html, frontmatter } = prices

  return (
    <Layout breadcrumbs={[{ title: "Cennik", url: "/cennik" }]}>
      <SEO title="Cennik" />
      <div className="row align-items-center section-padding-xs paragraph-content">
        <div className="col-md-6">
          <div
            className="text-box-padding-xs-right"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className="col-md-6">
          <Img fluid={frontmatter.photo.childImageSharp.fluid} alt="Banner" />
        </div>
      </div>
      <section className="section-padding-xs">
        <h2 className="section-title">Cennnik usług księgowych</h2>
        <div className="row paragraph-content">
          <div className="col-md-6 mb-4">
            <div className="gray-bg py-4 px-4 h-100">
              <h3>Ryczałt od przychodów ewidencjonowanych</h3>
              <table className="table table-sm styled-table">
                <thead>
                  <tr>
                    <th className="text-center" colSpan="2">
                      RYCZAŁT bez VAT
                    </th>
                    <th className="text-center" colSpan="2">
                      RYCZAŁT z VAT
                    </th>
                  </tr>
                  <tr className="col-description">
                    <th>liczba dokumentów</th>
                    <th>cena brutto</th>
                    <th>liczba dokumentów</th>
                    <th>cena brutto</th>
                  </tr>
                </thead>
                <tbody>
                  {frontmatter.ksiegowosc.ryczalt.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.int}</td>
                        <td>{row.priceNoVat}</td>
                        <td>{row.int}</td>
                        <td>{row.priceWithVat}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="gray-bg py-4 px-4 h-100">
              <h3>Podatkowa Księga Przychodów i Rozchodów</h3>
              <table className="table table-sm styled-table">
                <thead>
                  <tr>
                    <th className="text-center" colSpan="2">
                      PKPIR bez VAT
                    </th>
                    <th className="text-center" colSpan="2">
                      PKPIR z VAT
                    </th>
                  </tr>
                  <tr className="col-description">
                    <th>liczba dokumentów</th>
                    <th>cena brutto</th>
                    <th>liczba dokumentów</th>
                    <th>cena brutto</th>
                  </tr>
                </thead>
                <tbody>
                  {frontmatter.ksiegowosc.kpir.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.int}</td>
                        <td>{row.priceNoVat}</td>
                        <td>{row.int}</td>
                        <td>{row.priceWithVat}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6">
            <div className="gray-bg py-4 px-4 h-100">
              <h3>Księgi Handlowe</h3>
              <table className="table table-sm styled-table">
                <thead>
                  <tr>
                    <th className="text-center" colSpan="2">
                      KH bez VAT
                    </th>
                    <th className="text-center" colSpan="2">
                      KH z VAT
                    </th>
                  </tr>
                  <tr className="col-description">
                    <th>liczba dokumentów</th>
                    <th>cena brutto</th>
                    <th>liczba dokumentów</th>
                    <th>cena brutto</th>
                  </tr>
                </thead>
                <tbody>
                  {frontmatter.ksiegowosc.ksiegi.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.int}</td>
                        <td>{row.priceNoVat}</td>
                        <td>{row.int}</td>
                        <td>{row.priceWithVat}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6">
            <div className="gray-bg py-4 px-4 h-100">
              <h3>Usługi dodatkowe</h3>
              <table className="table table-sm styled-table">
                <thead>
                  <tr>
                    <th>rodzaj usługi</th>
                    <th>cena brutto</th>
                  </tr>
                </thead>
                <tbody>
                  {frontmatter.ksiegowosc.additionalServices.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.name}</td>
                        <td>{row.price}</td>
                      </tr>
                    )
                  })}
                </tbody>
                <thead>
                  <tr>
                    <th>rodzaj usługi</th>
                    <th>cena brutto</th>
                  </tr>
                </thead>
                <tbody>
                  {frontmatter.ksiegowosc.additionalServices2.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.name}</td>
                        <td>{row.price}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding-xs">
        <h2 className="section-title">Cennnik usług kadrowych i płacowych</h2>
        <div className="row paragraph-content">
          <div className="col-md-6 mb-4">
            <div className="gray-bg py-4 px-4 h-100">
              <h3>Kadry i płace</h3>
              <table className="table table-sm table-bordered styled-table">
                <thead>
                  <tr>
                    <th rowSpan="2">
                      <small>
                        rodzaj umowy / <br />
                        liczba pracowników
                      </small>
                    </th>
                    <th>KADRY</th>
                    <th>PŁACE</th>
                    <th>KADRY + PŁACE</th>
                  </tr>
                  <tr>
                    <th colSpan="4" className="text-center">
                      cena brutto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {frontmatter.kadry.kadryPlace.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.name}</td>
                        <td>{row.kadry}</td>
                        <td>{row.place}</td>
                        <td>{row.kadryIPlace}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="gray-bg py-4 px-4 h-100">
              <h3>Usługi dodatkowe</h3>
              <table className="table table-sm styled-table">
                <thead>
                  <tr>
                    <th>rodzaj usługi</th>
                    <th>cena brutto</th>
                  </tr>
                </thead>
                <tbody>
                  {frontmatter.kadry.additionalServices.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.name}</td>
                        <td>{row.price}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <p>
          Cennik nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu
          Cywilnego. Zastrzegamy sobie prawo zmiany podanych cen.
        </p>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prices: markdownRemark(fileAbsolutePath: { regex: "/(pages/prices)/" }) {
      html
      frontmatter {
        title
        photo {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ksiegowosc {
          ryczalt {
            int
            priceWithVat
            priceNoVat
          }
          kpir {
            int
            priceWithVat
            priceNoVat
          }
          ksiegi {
            int
            priceWithVat
            priceNoVat
          }
          additionalServices {
            name
            price
          }
          additionalServices2 {
            name
            price
          }
        }
        kadry {
          kadryPlace {
            name
            kadry
            place
            kadryIPlace
          }
          additionalServices {
            name
            price
          }
        }
      }
    }
  }
`
